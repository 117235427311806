import classes from './PostThumbnailMedia.image.module.scss';

import React from 'react';
import Img from '@lobox/uikit/Image';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';
import { removeQueryString } from '@lobox/utils';

interface FeedImageProps {
  src: string;
  secondaryUrl?: string;
  size?: 'tiny' | 'medium';
  className?: string;
  innerWrapperClassName?: string;
  showOriginalSrc?: boolean;
}

const PostThumbnailMediaImage: React.FC<FeedImageProps> = ({
  size = 'medium',
  src,
  secondaryUrl,
  className,
  innerWrapperClassName,
  showOriginalSrc,
}) => {
  const isTiny = size === 'tiny';
  const cleanSrc = removeQueryString(secondaryUrl || src);

  return (
    <Flex
      className={cnj(
        classes.postThumbnailMediaImageRoot,
        isTiny && classes.tinyPostRoot,
        className
      )}
    >
      <Flex
        className={cnj(
          classes.imgWrapper,
          isTiny && classes.tinyImgWrapper,
          innerWrapperClassName
        )}
      >
        <Img
          loading="lazy"
          resolution="original"
          src={showOriginalSrc ? src : cleanSrc}
          className={classes.image}
          defaultTag
        />
      </Flex>
    </Flex>
  );
};

export default PostThumbnailMediaImage;
