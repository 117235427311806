import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import GoogleMap from '@lobox/uikit/GoogleMap';
import Image from '@lobox/uikit/Image';
import useTheme from '@lobox/uikit/utils/useTheme';
import HighlightImage from '@shared/svg/Highlights';
import HighlightPattern from '@shared/svg/HighlightPattern.svg';
import postTypes from '@shared/constants/postTypes';
import useFeedElement from '@shared/components/Organism/FeedCard/Context/useFeedElement';
import PostThumbnailMediaImage from './PostThumbnailMedia.image';
import PostThumbnailMediaGallery from './PostThumbnailMedia.gallery';
import PostThumbnailOgLink from './PostThumbnailMedia.ogLink';
import PostThumbnailMediaVideo from './PostThumbnailMedia.video';
import classes from './PostThumbnailMedia.component.module.scss';
import useCssVariables from '@shared/hooks/useCssVariables';

interface Props {
  className?: string;
  imgClassName?: string;
  size: 'small' | 'medium';
  setVideoRef: (ref: React.MutableRefObject<HTMLVideoElement>) => null;
}

const PostThumbnailMedia = ({
  setVideoRef,
  className,
  size,
  imgClassName,
}: Props): JSX.Element => {
  const sizeInNumber = size === 'small' ? 124 : 240;
  const cssVars = useCssVariables({
    scope: classes.mediaWrapper,
    variables: { size: `${sizeInNumber}px` },
  });
  const { isDark } = useTheme();

  const {
    medias,
    location,
    feedMediaType,
    highlightData,
    linksListInBody,
    videos,
    images,
    post,
  } = useFeedElement();
  const highlightType = highlightData?.type;
  const video = videos?.[0];
  const image = images?.[0]?.url;

  const position = {
    lat: location?.lat,
    lon: location?.lon,
  };

  const postMedias = {
    [postTypes.HIGHLIGHT]: (
      <>
        <Image src={HighlightPattern} className={classes.pattern} />
        <HighlightImage type={highlightType} />
      </>
    ),
    [postTypes.CHECK_IN]: (
      <GoogleMap
        draggable={false}
        isClickAble={false}
        value={position}
        defaultZoom={10}
        center={[position.lat, position.lon]}
        className={classes.map}
      />
    ),
    [postTypes.VIDEO]: video ? (
      <PostThumbnailMediaVideo
        size={sizeInNumber}
        setVideoRef={setVideoRef}
        video={video}
      />
    ) : null,
    [postTypes.PHOTO]: image ? (
      <PostThumbnailMediaImage
        src={image}
        innerWrapperClassName={imgClassName}
      />
    ) : null,
    [postTypes.GALLERY]: medias?.length ? (
      <PostThumbnailMediaGallery size={size} medias={medias} />
    ) : null,
    [postTypes.OGLink]: linksListInBody?.length ? (
      <PostThumbnailOgLink
        body={post.body || highlightData?.description}
        url={linksListInBody[0]}
      />
    ) : null,
  } as any;

  return (
    <Flex
      className={cnj(
        classes.mediaWrapper,
        feedMediaType === postTypes.HIGHLIGHT && classes.highlightWrapper,
        isDark && classes.darkBg,
        className
      )}
    >
      {cssVars}
      {postMedias[feedMediaType]}
    </Flex>
  );
};

export default PostThumbnailMedia;
