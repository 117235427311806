import classes from './PostThumbnailMedia.ogLink.module.scss';

import React from 'react';
import {
  appEnvironment,
  isPropsChanged,
  useGetLinkMetaData,
  useTranslation,
} from '@lobox/utils';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Skeleton from '@lobox/uikit/Skeleton';
import Typography from '@lobox/uikit/Typography';
import PostThumbnailMediaImage from './PostThumbnailMedia.image';

interface LinkPreviewProps {
  url: string;
  body?: string;
}

const PostThumbnailOgLink: React.FC<LinkPreviewProps> = ({ body, url }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetLinkMetaData({
    url,
    body,
    linkPreviewUrl: appEnvironment.reactLinkPreview,
  });

  if (isLoading) {
    return <Skeleton className={classes.skeleton} />;
  }
  return data?.image ? (
    <PostThumbnailMediaImage showOriginalSrc src={data.image} />
  ) : (
    <Flex className={classes.noMedialRoot}>
      <IconButton
        colorSchema="semi-transparent"
        noHover
        size="mlg"
        variant="circle"
        name="eye-slash"
      />
      <Typography
        textAlign="center"
        mt={12}
        color="smoke_coal"
        font="700"
        size={16}
      >
        {t('no_media_available')}
      </Typography>
    </Flex>
  );
};

export default React.memo(PostThumbnailOgLink, isPropsChanged(['url']));
