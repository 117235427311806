import * as React from 'react';

const CommentCelebrate: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M12.7255 15.2742L11.6436 18.3427L7.63797 20.3618L7.11133 20.1578L5.08789 13.7736L6.3375 10.518C6.43703 10.2588 6.6607 10.0674 6.9325 10.01C7.2043 9.95202 7.48703 10.0357 7.68336 10.232L12.7255 15.2742Z"
        fill="#EEBD53"
      />
      <path
        d="M17.9905 21.0667C17.9325 21.3385 17.7411 21.5627 17.4819 21.6622L14.2269 22.9118L7.63867 20.3617L12.7263 15.2742L17.7679 20.3164C17.9642 20.5127 18.0479 20.7949 17.9905 21.0667Z"
        fill="#BE9742"
      />
      <path
        d="M9.65732 18.3426L8.60393 22.2029L0.240169 27.7597C0.0110288 27.5306 -0.0617053 27.1882 0.0547786 26.8853L5.0882 13.7734L9.65732 18.3426Z"
        fill="#43A047"
      />
      <path
        d="M14.2334 9.73209C14.2333 9.73209 14.2333 9.73209 14.2333 9.73209C13.7803 9.73203 13.413 9.36475 13.4131 8.91166C13.4133 7.57548 14.5004 6.48841 15.8366 6.48841H15.8449C16.2766 6.48841 16.6278 6.1372 16.6278 5.70555V5.69719C16.6278 4.36101 17.7149 3.27382 19.051 3.27371C19.4831 3.27366 19.8343 2.92245 19.8343 2.49086C19.8343 1.15452 20.9215 0.0673828 22.2578 0.0673828C22.7108 0.0673828 23.0781 0.434664 23.0781 0.887695C23.0781 1.34073 22.7108 1.70801 22.2578 1.70801C21.826 1.70801 21.4749 2.05921 21.4749 2.49086C21.4749 3.82704 20.3879 4.91417 19.0517 4.91434C18.6196 4.91434 18.2684 5.26554 18.2684 5.69719V5.70555C18.2684 7.0419 17.1812 8.12903 15.8449 8.12903H15.8366C15.4049 8.12903 15.0538 8.48018 15.0537 8.91183C15.0537 9.36486 14.6864 9.73209 14.2334 9.73209Z"
        fill="#5384EE"
      />
      <path
        d="M19.1553 14.5865H19.1552C18.7021 14.5865 18.3349 14.2192 18.335 13.7661C18.3351 12.4299 19.4223 11.3429 20.7584 11.3429H20.7668C21.1985 11.3429 21.5497 10.9917 21.5497 10.56V10.5517C21.5497 9.21545 22.6367 8.12831 23.9729 8.1282C24.405 8.1282 24.7561 7.777 24.7561 7.34535C24.7561 6.00901 25.8433 4.92188 27.1796 4.92188C27.6327 4.92188 27.9999 5.28916 27.9999 5.74219C27.9999 6.19522 27.6327 6.5625 27.1796 6.5625C26.7479 6.5625 26.3968 6.9137 26.3968 7.34535C26.3968 8.68159 25.3097 9.76872 23.9735 9.76883C23.5414 9.76883 23.1903 10.12 23.1903 10.5517V10.56C23.1903 11.8964 22.1031 12.9835 20.7668 12.9835H20.7584C20.3268 12.9835 19.9756 13.3347 19.9756 13.7663C19.9755 14.2193 19.6083 14.5865 19.1553 14.5865Z"
        fill="#4269BE"
      />
      <path
        d="M13.6445 1.64062C14.0976 1.64062 14.4648 1.27336 14.4648 0.820312C14.4648 0.367266 14.0976 0 13.6445 0C13.1915 0 12.8242 0.367266 12.8242 0.820312C12.8242 1.27336 13.1915 1.64062 13.6445 1.64062Z"
        fill="#FFB760"
      />
      <path
        d="M5.79688 6.5625C6.24992 6.5625 6.61719 6.19523 6.61719 5.74219C6.61719 5.28914 6.24992 4.92188 5.79688 4.92188C5.34383 4.92188 4.97656 5.28914 4.97656 5.74219C4.97656 6.19523 5.34383 6.5625 5.79688 6.5625Z"
        fill="#FFB760"
      />
      <path
        d="M9.82463 6.89834C9.83617 6.90403 9.84798 6.90945 9.85985 6.91464C10.2753 7.09544 10.7586 6.90524 10.9393 6.48977C10.9698 6.41983 11.6747 4.76039 10.8069 3.30007C10.5755 2.91059 10.0721 2.78251 9.68266 3.01389C9.29318 3.24533 9.16505 3.74867 9.39648 4.13816C9.82573 4.86052 9.43909 5.82576 9.43498 5.83517C9.25944 6.2386 9.43384 6.70617 9.82463 6.89834Z"
        fill="#EEBD53"
      />
      <path
        d="M27.1797 15.176C27.6327 15.176 28 14.8088 28 14.3557C28 13.9027 27.6327 13.5354 27.1797 13.5354C26.7266 13.5354 26.3594 13.9027 26.3594 14.3557C26.3594 14.8088 26.7266 15.176 27.1797 15.176Z"
        fill="#FFB760"
      />
      <path
        d="M22.2578 23.0234C22.7109 23.0234 23.0781 22.6562 23.0781 22.2031C23.0781 21.7501 22.7109 21.3828 22.2578 21.3828C21.8048 21.3828 21.4375 21.7501 21.4375 22.2031C21.4375 22.6562 21.8048 23.0234 22.2578 23.0234Z"
        fill="#FFB760"
      />
      <path
        d="M21.1013 18.1755C21.0956 18.164 21.0902 18.1522 21.085 18.1403C20.9042 17.7249 21.0944 17.2416 21.5098 17.0608C21.5798 17.0304 23.2392 16.3255 24.6995 17.1932C25.089 17.4246 25.2171 17.928 24.9857 18.3175C24.7543 18.707 24.2509 18.8351 23.8614 18.6037C23.1391 18.1744 22.1739 18.5611 22.1644 18.5652C21.7609 18.7407 21.2934 18.5663 21.1013 18.1755Z"
        fill="#EEBD53"
      />
      <path
        d="M14.2265 22.9119L1.11468 27.9453C0.814998 28.0613 0.470468 27.9902 0.240234 27.7599L9.65738 18.3428L14.2265 22.9119Z"
        fill="#358038"
      />
    </g>
  </svg>
);

export default CommentCelebrate;
