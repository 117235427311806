import classes from './Boost.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Boost from '@shared/svg/CommentBoost';
import Layout from './ReactionLayout';
import type { ReactionProps } from './ReactionLayout';

interface BoostProps extends ReactionProps {
  hasHover?: boolean;
}

const BoostReaction: React.FC<BoostProps> = ({
  className,
  hasHover = true,
  ...rest
}) => {
  return (
    <Layout
      className={cnj(
        hasHover && classes.hoverClass,
        classes.boostContainer,
        className
      )}
      {...rest}
    >
      <Boost />
    </Layout>
  );
};

export default BoostReaction;
