export const initialState = {
  feedElement: {},
  variant: 'simple',
  isTempHidden: false,
  unFollowed: false,
  isBlocked: false,
  isOpenComments: false,
  isReportModalOpen: false,
  isOpenReactionsInBottomSheet: false,
  selectedReactionInBottomSheet: null,
  visibleNetworkInfoOnHeader: false,
  replyClickCounter: 0,
  commentToEdit: undefined,
  selectedComment: null,
};

type ActionType =
  | {
      type: 'HIDE_POST';
    }
  | {
      type: 'UNDO_HIDE';
    }
  | {
      type: 'UNDO_BLOCK';
    }
  | {
      type: 'UN_FOLLOW_POST_OWNER';
      payload: string;
    }
  | {
      type: 'UNDO_UN_FOLLOW';
    }
  | {
      type: 'BLOCK_USER';
    }
  | {
      type: 'TOGGLE_COMMENTS';
    }
  | { type: 'TOGGLE_COMMENT_TO_EDIT'; payload: { comment: any } }
  | { type: 'SELECT_COMMENT_TO_REPLY'; payload: { comment: any; ref?: any } }
  | {
      type: 'SET_REPLY_CLICK_COUNTER';
      payload: number;
    }
  | {
      type: 'SET_COMMENT_COUNTER';
      payload: number;
    }
  | {
      type: 'TOGGLE_REACTIONS_IN_BOTTOM_SHEET';
      payload?: any;
    };

type StateType = typeof initialState;

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'HIDE_POST':
      return {
        ...state,
        isTempHidden: true,
      };
    case 'UNDO_HIDE':
      return {
        ...state,
        isTempHidden: false,
      };
    case 'UN_FOLLOW_POST_OWNER':
      return {
        ...state,
        unFollowed: true,
        unFollowSender: action.payload,
      };
    case 'UNDO_UN_FOLLOW':
      return {
        ...state,
        unFollowed: false,
      };
    case 'BLOCK_USER':
      return {
        ...state,
        isBlocked: true,
      };
    case 'UNDO_BLOCK':
      return {
        ...state,
        isBlocked: false,
      };
    case 'TOGGLE_COMMENTS':
      return {
        ...state,
        isOpenComments:
          typeof action?.payload === 'boolean'
            ? action?.payload
            : !state.isOpenComments,
      };
    case 'TOGGLE_REACTIONS_IN_BOTTOM_SHEET':
      return {
        ...state,
        isOpenReactionsInBottomSheet: !state.isOpenReactionsInBottomSheet,
        selectedReactionInBottomSheet: action.payload,
      };
    case 'TOGGLE_COMMENT_TO_EDIT':
      return {
        ...state,
        commentToEdit: action.payload.comment,
      };
    case 'SELECT_COMMENT_TO_REPLY':
      return {
        ...state,
        selectedComment: action.payload,
      };
    case 'SET_REPLY_CLICK_COUNTER':
      return {
        ...state,
        replyClickCounter: state.replyClickCounter + action.payload,
      };
    case 'SET_COMMENT_COUNTER':
      return {
        ...state,
        feedElement: {
          ...state.feedElement,
          post: {
            ...state.feedElement.post,
            commentCounter: action.payload,
          },
        },
      };
    default: {
      throw new Error(`Unsupported action type at FeedCard Reducer`);
    }
  }
}
