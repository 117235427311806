import classes from './PostThumbnailMedia.gallery.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import PostThumbnailMediaImage from './PostThumbnailMedia.image';

interface Props {
  medias: Array<any>;
  size: 'small' | 'medium';
}

const PostThumbnailMediaGallery: React.FC<Props> = ({ size, medias }) => {
  const items = medias?.slice(0, 4);

  return (
    <Flex className={classes.postThumbnailMediaGalleryRoot}>
      {items?.map((item, index) => (
        <Flex
          className={cnj(
            classes.item,
            size === 'small' ? classes.item_small : classes.item_big,
            items?.length === 2 && classes.fullWidth,
            items?.length === 3 && index === 2 && classes.fullWidth
          )}
        >
          <PostThumbnailMediaImage
            src={item.url}
            secondaryUrl={item.secondaryUrl}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export default PostThumbnailMediaGallery;
