import * as React from 'react';

const CommentNotRelevant: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3371_174)">
      <path
        d="M24.9429 5.38672C24.6312 4.9437 24.2539 4.53354 23.8602 4.13984C23.4664 3.74615 23.0562 3.3687 22.6133 3.05698C20.1687 1.10469 17.1664 0 14 0C10.2757 0 6.7812 1.49844 4.13984 4.13984C1.49849 6.78125 0 10.2757 0 14C0 17.1828 1.10469 20.1851 3.05709 22.6297C3.38521 23.0561 3.74609 23.4663 4.13984 23.8602C4.53359 24.2539 4.94375 24.6148 5.37031 24.9429C7.81479 26.8953 10.8172 28 14 28C17.7243 28 21.2188 26.5016 23.8602 23.8602C26.5016 21.2187 28 17.7242 28 14C28 10.8336 26.8953 7.83125 24.9429 5.38672ZM3.33594 14C3.33594 11.1453 4.45156 8.47109 6.45318 6.45307C8.47109 4.45156 11.1453 3.33594 14 3.33594C16.2969 3.33594 18.4789 4.05781 20.2836 5.38672L5.40313 20.2835C4.05781 18.4789 3.33594 16.2968 3.33594 14ZM21.5468 21.5468C19.5289 23.5484 16.8547 24.6641 14 24.6641C11.7031 24.6641 9.52115 23.9422 7.71635 22.5969L22.6133 7.71641C23.9422 9.52104 24.6641 11.7031 24.6641 14C24.6641 16.8547 23.5484 19.5289 21.5468 21.5468Z"
        fill="#D32F2F"
      />
      <path
        d="M5.37012 24.943C7.81459 26.8954 10.817 28 13.9998 28C17.7241 28 21.2186 26.5016 23.86 23.8602C26.5014 21.2187 27.9998 17.7242 27.9998 14C27.9998 10.8336 26.8951 7.8313 24.9427 5.38677C24.631 4.94374 24.2537 4.53359 23.86 4.13989L4.13965 23.8602C4.53334 24.254 4.9435 24.6148 5.37012 24.943ZM22.6131 7.7164C23.942 9.52109 24.6639 11.7031 24.6639 14C24.6639 16.8547 23.5482 19.529 21.5466 21.5469C19.5287 23.5485 16.8545 24.6641 13.9998 24.6641C11.7029 24.6641 9.52095 23.9422 7.71616 22.5969L22.6131 7.7164Z"
        fill="#A82525"
      />
    </g>
    <defs>
      <clipPath id="clip0_3371_174">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CommentNotRelevant;
