import classes from './ReactionLayout.module.scss';

import React from 'react';
import { motion } from 'framer-motion';

import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import { preventClickHandler } from '@lobox/utils';

export interface ReactionProps {
  className?: string;
  size?: 'small' | 'large';
  hideContainer?: boolean;
  onClick: Function;
}

const LikeReaction: React.FC<ReactionProps> = ({
  size = 'large',
  className,
  hideContainer = false,
  children,
  onClick,
  ...rest
}) => {
  const isLarge = size === 'large';

  const onClickHandler = (e) => {
    if (onClick) {
      onClick(e);
      preventClickHandler(e);
    }
  };

  return (
    <BaseButton
      className={cnj(
        hideContainer ? classes.hideContainer : classes.container,
        isLarge &&
          (hideContainer ? classes.hideContainerLarge : classes.containerLarge),
        className
      )}
      {...rest}
      onClick={onClickHandler}
    >
      <motion.div
        className={classes.content}
        whileHover={{
          rotate: 20,
        }}
      >
        {children}
      </motion.div>
    </BaseButton>
  );
};

export default LikeReaction;
