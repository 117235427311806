import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';
import type { FC } from 'react';

import JobDark from './jobDark.svg';
import JobLight from './jobLight.svg';
import SchoolDark from './schoolDark.svg';
import SchoolLight from './schoolLight.svg';
import TravelDark from './travelDark.svg';
import TravelLight from './travelLight.svg';
import LicenseDark from './licenseDark.svg';
import LicenseLight from './licenseLight.svg';
import LanguageDark from './languageDark.svg';
import LanguageLight from './languageLight.svg';
import SimpleDark from './simpleDark.svg';
import SimpleLight from './simpleLight.svg';
import CourseDark from './courseDark.svg';
import CourseLight from './courseLight.svg';
import InstrumentDark from './instrumentDark.svg';
import InstrumentLight from './instrumentLight.svg';
import FundDark from './fundDark.svg';
import FundLight from './fundLight.svg';
import AwardDark from './awardDark.svg';
import AwardLight from './awardLight.svg';
import PromoteDark from './promoteDark.svg';
import PromoteLight from './promoteLight.svg';
import GraduationDark from './graduationDark.svg';
import GraduationLight from './graduationLight.svg';
import HobbyDark from './hobbyDark.svg';
import HobbyLight from './hobbyLight.svg';
import PatentDark from './patentDark.svg';
import PatentLight from './patentLight.svg';
import CertificateDark from './certificationDark.svg';
import CertificateLight from './certificationLight.svg';
import EntrepreneurshipDark from './entrepreneurshipDark.svg';
import EntrepreneurshipLight from './entrepreneurshipLight.svg';
import StudyDark from './studyDark.svg';
import StudyLight from './studyLight.svg';
import SportDark from './sportDark.svg';
import SportLight from './sportLight.svg';
import PublicationDark from './publicationDark.svg';
import PublicationLight from './publicationLight.svg';
import SkillDark from './skillDark.svg';
import SkillLight from './skillLight.svg';
import AdventureDark from './adventureDark.svg';
import AdventureLight from './adventureLight.svg';
import ConferenceDark from './conferenceDark.svg';
import ConferenceLight from './conferenceLight.svg';
import BussinesFundDark from './businessFundDark.svg';
import BussinesFundLight from './businessFundLight.svg';
import AcquiredDark from './acquiredDark.svg';
import AcquiredLight from './acquiredLight.svg';
import LocationDark from './locationDark.svg';
import LocationLight from './locationLight.svg';
import NameDark from './nameDark.svg';
import NameLight from './nameLight.svg';
import MarketValueDark from './marketValueDark.svg';
import MarketValueLight from './marketValueLight.svg';
import StockValueDark from './stockValueDark.svg';
import StockValueLight from './stockValueLight.svg';
import InvestDark from './investDark.svg';
import InvestLight from './investLight.svg';
import IpoDark from './ipoDark.svg';
import IpoLight from './ipoLight.svg';
import VolunteeringLight from './volunteeringLight.svg';
import VolunteeringDark from './volunteeringDark.svg';
import AnniversaryLight from './anniversaryLight.svg';
import AnniversaryDark from './anniversaryDark.svg';
import NewMemberLight from './newMemberLight.svg';
import NewMemberDark from './newMemberDark.svg';
import Image from '@lobox/uikit/Image';

export const allHighlights = {
  JOB: [JobLight, JobDark],
  SCHOOL: [SchoolLight, SchoolDark],
  TRAVEL: [TravelLight, TravelDark],
  LICENSE: [LicenseLight, LicenseDark],
  LANGUAGE: [LanguageLight, LanguageDark],
  SIMPLE: [SimpleLight, SimpleDark],
  COURSE: [CourseLight, CourseDark],
  INSTRUMENT: [InstrumentLight, InstrumentDark],
  GET_FUNDED: [FundLight, FundDark],
  AWARD: [AwardLight, AwardDark],
  GET_PROMOTED: [PromoteLight, PromoteDark],
  GRADUATION: [GraduationLight, GraduationDark],
  HOBBY: [HobbyLight, HobbyDark],
  PATENT: [PatentLight, PatentDark],
  CERTIFICATION: [CertificateLight, CertificateDark],
  ENTREPRENEURSHIP: [EntrepreneurshipDark, EntrepreneurshipLight],
  STUDY_ABROAD: [StudyLight, StudyDark],
  SPORT: [SportLight, SportDark],
  PUBLICATION: [PublicationLight, PublicationDark],
  SKILL: [SkillLight, SkillDark],
  ADVENTURE: [AdventureLight, AdventureDark],
  CONFERENCE_PARTICIPATION: [ConferenceLight, ConferenceDark],
  BUSSINES_GET_FUNDED: [BussinesFundLight, BussinesFundDark],
  ACQUIRED: [AcquiredLight, AcquiredDark],
  NEW_LOCATION: [LocationLight, LocationDark],
  CHANGED_NAME: [NameLight, NameDark],
  MARKET_VALUE: [MarketValueLight, MarketValueDark],
  STOCK_VALUE_CHANGE: [StockValueLight, StockValueDark],
  GET_INVESTED: [InvestLight, InvestDark],
  IPO: [IpoLight, IpoDark],
  VOLUNTEERING: [VolunteeringLight, VolunteeringDark],
  ANNIVERSARY: [AnniversaryLight, AnniversaryDark],
  NEW_TEAM_MEMBER: [NewMemberLight, NewMemberDark],
};

interface HighlightImageProps {
  type: keyof typeof allHighlights;
  className?: string;
}

const HighlightImage: FC<HighlightImageProps> = (props) => {
  const { type, className } = props;

  const { isDark } = useTheme();

  const result = allHighlights?.[type]?.[isDark ? 1 : 0] || '';

  return <Image defaultTag src={result} className={className} />;
};

export default HighlightImage;
