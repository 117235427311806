import classes from './DisLike.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Dislike from '@shared/svg/CommentDislike';
import Layout from './ReactionLayout';
import type { ReactionProps } from './ReactionLayout';

interface DisLikeProps extends ReactionProps {
  hasHover?: boolean;
}

const DisLikeReaction: React.FC<DisLikeProps> = ({
  className,
  hasHover = true,
  ...rest
}) => {
  return (
    <Layout
      className={cnj(
        hasHover && classes.hoverClass,
        classes.dislikeContainer,
        className
      )}
      {...rest}
    >
      <Dislike />
    </Layout>
  );
};

export default DisLikeReaction;
