import classes from './index.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import postTypes from '@shared/constants/postTypes';

interface Props {
  feedMediaType: keyof typeof postTypes;
  isSelected?: boolean;
  className?: string;
}

const PostMediaBadge = ({
  className,
  feedMediaType,
  isSelected,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const iconNames = {
    [postTypes.HIGHLIGHT]: 'calendar-check',
    [postTypes.CHECK_IN]: 'map-marker-alt',
    [postTypes.VIDEO]: 'play',
    [postTypes.PHOTO]: 'image',
    [postTypes.TEXT]: 'subtitles',
    [postTypes.OGLink]: 'subtitles',
    [postTypes.GALLERY]: 'gallery',
  };

  const titles = {
    [postTypes.HIGHLIGHT]: 'highlight',
    [postTypes.CHECK_IN]: 'checkin',
    [postTypes.VIDEO]: 'VIDEO',
    [postTypes.PHOTO]: 'image',
    [postTypes.TEXT]: 'text_cap',
    [postTypes.OGLink]: 'text_cap',
    [postTypes.GALLERY]: 'gallery',
  };

  return (
    <Flex className={cnj(classes.badge, className)}>
      <IconButton
        colorSchema={isSelected ? 'tertiary-transparent' : 'transparent1'}
        noHover
        size="sm"
        name={iconNames[feedMediaType]}
        type="far"
      />
      <Typography
        color={isSelected ? 'brand' : 'primaryText'}
        size={15}
        height={18}
        font="500"
      >
        {t(titles[feedMediaType])}
      </Typography>
    </Flex>
  );
};

export default PostMediaBadge;
