import classes from './SearchPosts.itemBody.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import RichTextView from '@lobox/uikit/RichText/RichTextView';
import Typography from '@lobox/uikit/Typography';
import Image from '@lobox/uikit/Image';
import useTheme from '@lobox/uikit/utils/useTheme';
import { routeNames, translateReplacer, useTranslation } from '@lobox/utils';
import convertSecondsToDigitString from '@shared/utils/convertSecondsToDigitString';
import useFeedElement from '@shared/components/Organism/FeedCard/Context/useFeedElement';
import HighlightImage from '@shared/svg/Highlights';
import HighlightPattern from '@shared/svg/HighlightPattern.svg';
import PostThumbnailMediaImage from '@shared/components/Organism/PostThumbnailMedia/PostThumbnailMedia.image';
import { usePathname } from 'next/navigation';

interface Props {
  className?: string;
}

const SearchPostsItemBody = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isDark } = useTheme();
  const {
    isHighlight,
    location,
    medias,
    linksListInBody,
    highlightData,
    post,
    images,
    videos,
    feedMediaType,
  } = useFeedElement();
  const richTextHtml =
    post.body || post?.sharedOf?.body || highlightData?.description;
  const image = images?.[0]?.url;
  const video = videos?.[0]?.url;
  const pathname = usePathname();
  const isSearchAllPage = pathname?.includes(routeNames.searchAll);

  if (linksListInBody?.length > 0) {
    return (
      <Flex
        className={cnj(classes.itemBodyRoot, classes.linksWrapper, className)}
      >
        <Typography
          color="brand"
          className={classes.linkText}
          lineNumber={1}
          isWordWrap
          isTruncated
        >
          {linksListInBody[0]}
        </Typography>
      </Flex>
    );
  }

  if (richTextHtml) {
    return (
      <RichTextView
        html={richTextHtml}
        typographyProps={{
          size: 15,
          color: 'thirdText',
        }}
        row={2}
        showMore
        seeMoreClassName={cnj(
          classes.disabledClick,
          !isSearchAllPage && classes.seeMoreClassName
        )}
        className={classes.richTextContainer}
      />
    );
  }
  const highlightType = highlightData?.type;
  const isGallery = medias?.length > 1;
  const { searchParams } = image || video ? new URL(image || video) : {};
  const visibleVideoTag = !isGallery && searchParams && video;
  const visibleImageTag =
    (feedMediaType === 'PHOTO' &&
      !isGallery &&
      searchParams &&
      image &&
      searchParams.get('bw') > 0 &&
      searchParams.get('bh') > 0) ||
    (feedMediaType !== 'PHOTO' && image && !isGallery);

  const items = [
    isHighlight && {
      title: t(`NEW_${highlightType}`),
      icon: (
        <Flex className={cnj(classes.listItemSvg, isDark && classes.darkBg)}>
          <Image src={HighlightPattern} className={classes.pattern} />
          <HighlightImage type={highlightType} />
        </Flex>
      ),
    },

    visibleImageTag && {
      title:
        feedMediaType === 'PHOTO'
          ? `${searchParams.get('bw')}x${searchParams.get('bh')}`
          : t('image'),
      icon:
        feedMediaType === 'PHOTO' ? (
          <IconButton
            size="md18"
            className={classes.icon}
            name="image-size"
            type="far"
            colorSchema="semi-transparent"
          />
        ) : (
          <PostThumbnailMediaImage size="tiny" src={image} />
        ),
    },
    visibleVideoTag && {
      title: searchParams.get('du')
        ? convertSecondsToDigitString(searchParams.get('du'))
        : t('no_du_capt'),
      icon: (
        <IconButton
          size="md18"
          className={classes.icon}
          name="duraiton"
          type="far"
          colorSchema="semi-transparent"
        />
      ),
    },
    isGallery && {
      title: translateReplacer(t('images_count'), `${medias.length}`),
      icon: (
        <IconButton
          size="md18"
          className={classes.icon}
          name="gallery"
          type="far"
          colorSchema="semi-transparent"
        />
      ),
    },
    location && {
      title: location.title,
      icon: (
        <IconButton
          size="md18"
          className={classes.icon}
          name="location"
          colorSchema="semi-transparent"
          type="fal"
        />
      ),
    },
  ].reduce(
    (prev, curr) => (!curr || prev.length === 2 ? prev : [curr, ...prev]),
    []
  );

  return (
    <Flex className={cnj(classes.itemBodyRoot, className)}>
      {items.map(({ title, icon }, index) => (
        <Flex
          key={title || icon || index}
          className={cnj(
            classes.itemBodyWrapper,
            index !== 0 && classes.marginLeft
          )}
        >
          {icon}
          <Typography
            isTruncated
            lineNumber={1}
            isWordWrap
            font="700"
            height={18}
          >
            {title}
          </Typography>
        </Flex>
      ))}
    </Flex>
  );
};

export default SearchPostsItemBody;
