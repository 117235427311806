import { routeNames } from '@lobox/utils';
import { InternalLink } from '@shared/components/Organism/Message/components/molecules/InternalLinkPreview/InternalLinkPreview';
import { decodeHtmlString } from '@shared/utils/decodeHtmlString';
import { getUrls } from '@shared/utils/getUrlsFromText';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

export function useExtractInternalLinks(text?: string) {
  const [links, setLinks] = useState<InternalLink[]>([]);
  const hasLinkToPreview = links?.length > 0;

  const checkText = useCallback(
    debounce((text: string) => {
      if (!text) return setLinks([]);
      const _links: string[] = getUrls(text);
      //_links are different from the current state
      if (
        links?.length !== _links?.length ||
        links?.some((link, i) => link?.url !== _links[i])
      ) {
        const formattedNewLinks: typeof links = _links
          ?.map((link) => ({
            url: decodeHtmlString(link),
            type: getType(link),
          }))
          ?.filter((item) => !!item?.type);
        setLinks(formattedNewLinks);
      }
    }, 250),
    [links]
  );

  useEffect(() => {
    if (typeof text !== 'string') return;
    checkText(text);
  }, [text]);

  return { links, checkText, hasLinkToPreview };
}

export function getType(link: string): InternalLink['type'] {
  if (link?.includes(routeNames.schedulesAvailability)) {
    return 'availability';
  }
  return '';
}
