import * as React from 'react';

const CommentLike: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M18.8268 13.953H11.7184C11.3479 13.953 11.0233 13.7052 10.9256 13.3478C10.828 12.9905 10.9814 12.612 11.3003 12.4235C14.3974 10.5934 16.0062 8.45866 16.2183 5.89721C16.2923 5.00826 17.0841 4.2982 17.9829 4.30996C18.9 4.32461 19.7592 4.69222 20.4027 5.34519C21.0467 5.99876 21.4016 6.86425 21.402 7.78212C21.402 7.78223 21.402 7.78234 21.402 7.78245V8.05857C21.402 10.3008 20.7378 11.7304 19.5125 13.5842C19.3601 13.8145 19.1027 13.953 18.8268 13.953Z"
        fill="#FFC380"
      />
      <path
        d="M27.5383 14.8845C27.5383 13.4645 26.3831 12.3093 24.9631 12.3093H11.7183C11.5712 12.3093 11.4267 12.3488 11.3001 12.4237C10.3703 12.9735 9.43157 13.426 8.67727 13.7897C8.41416 13.9165 8.16566 14.0364 7.93964 14.1493C7.66123 14.2886 7.48535 14.5731 7.48535 14.8845V25.4041C7.48535 25.7154 7.66123 26 7.93964 26.1392C11.2604 27.7993 14.0208 28.0001 17.2398 28.0001C17.7383 28.0001 18.2481 27.9953 18.7723 27.9904C19.3521 27.9849 19.9515 27.9792 20.58 27.9792H23.1781C24.5981 27.9792 25.7533 26.824 25.7533 25.4041C25.7533 24.9267 25.6227 24.4792 25.3953 24.0955C26.1352 23.6425 26.6299 22.8268 26.6299 21.8975C26.6299 21.4201 26.4994 20.9726 26.272 20.5889C27.0119 20.136 27.5066 19.3202 27.5066 18.391C27.5066 17.7231 27.251 17.1138 26.8326 16.6558C27.2882 16.1756 27.5383 15.5493 27.5383 14.8845Z"
        fill="#FFCF99"
      />
      <path
        d="M25.829 7.71742H24.0758C23.6219 7.71742 23.2539 7.34943 23.2539 6.89557C23.2539 6.44172 23.6219 6.07373 24.0758 6.07373H25.829C26.2829 6.07373 26.6509 6.44172 26.6509 6.89557C26.6509 7.34943 26.2829 7.71742 25.829 7.71742Z"
        fill="#29CCB1"
      />
      <path
        d="M13.5566 7.71742H11.8033C11.3494 7.71742 10.9814 7.34943 10.9814 6.89557C10.9814 6.44172 11.3494 6.07373 11.8033 6.07373H13.5566C14.0104 6.07373 14.3784 6.44172 14.3784 6.89557C14.3784 7.34943 14.0105 7.71742 13.5566 7.71742Z"
        fill="#29CCB1"
      />
      <path
        d="M16.1876 3.1623C15.9035 3.1623 15.6274 3.01492 15.4752 2.75127L14.5985 1.23287C14.3716 0.839832 14.5063 0.337144 14.8993 0.110246C15.2923 -0.116707 15.795 0.0179882 16.0219 0.411027L16.8986 1.92943C17.1255 2.32246 16.9908 2.82515 16.5978 3.05205C16.4684 3.12681 16.327 3.1623 16.1876 3.1623Z"
        fill="#29CCB1"
      />
      <path
        d="M21.4452 3.16235C21.3058 3.16235 21.1644 3.12686 21.0351 3.0521C20.642 2.82515 20.5073 2.32251 20.7343 1.92947L21.6109 0.411076C21.8378 0.0180369 22.3405 -0.116768 22.7335 0.110295C23.1266 0.337248 23.2613 0.839881 23.0343 1.23292L22.1577 2.75132C22.0056 3.01497 21.7293 3.16235 21.4452 3.16235Z"
        fill="#29CCB1"
      />
      <path
        d="M5.61146 23.6612V27.9896H3.96777V23.6612C3.96777 23.2076 4.33598 22.8394 4.78962 22.8394C5.24325 22.8394 5.61146 23.2076 5.61146 23.6612Z"
        fill="#73C3FF"
      />
      <path
        d="M25.829 7.71742H24.0758C23.6219 7.71742 23.2539 7.34943 23.2539 6.89557C23.2539 6.44172 23.6219 6.07373 24.0758 6.07373H25.829C26.2829 6.07373 26.6509 6.44172 26.6509 6.89557C26.6509 7.34943 26.2829 7.71742 25.829 7.71742Z"
        fill="#5384EE"
      />
      <path
        d="M13.5566 7.71742H11.8033C11.3494 7.71742 10.9814 7.34943 10.9814 6.89557C10.9814 6.44172 11.3494 6.07373 11.8033 6.07373H13.5566C14.0104 6.07373 14.3784 6.44172 14.3784 6.89557C14.3784 7.34943 14.0105 7.71742 13.5566 7.71742Z"
        fill="#5384EE"
      />
      <path
        d="M16.1876 3.1623C15.9035 3.1623 15.6274 3.01492 15.4752 2.75127L14.5985 1.23287C14.3716 0.839832 14.5063 0.337144 14.8993 0.110246C15.2923 -0.116707 15.795 0.0179882 16.0219 0.411027L16.8986 1.92943C17.1255 2.32246 16.9908 2.82515 16.5978 3.05205C16.4684 3.12681 16.327 3.1623 16.1876 3.1623Z"
        fill="#5384EE"
      />
      <path
        d="M21.4452 3.16235C21.3058 3.16235 21.1644 3.12686 21.0351 3.0521C20.642 2.82515 20.5073 2.32251 20.7343 1.92947L21.6109 0.411076C21.8378 0.0180369 22.3405 -0.116768 22.7335 0.110295C23.1266 0.337248 23.2613 0.839881 23.0343 1.23292L22.1577 2.75132C22.0056 3.01497 21.7293 3.16235 21.4452 3.16235Z"
        fill="#5384EE"
      />
      <path
        d="M5.61146 23.6612V27.9896H3.96777V23.6612C3.96777 23.2076 4.33598 22.8394 4.78962 22.8394C5.24325 22.8394 5.61146 23.2076 5.61146 23.6612Z"
        fill="#73C3FF"
      />
      <path
        d="M8.29683 12.3093H1.28376C0.830125 12.3093 0.461914 12.6775 0.461914 13.1312V27.1573C0.461914 27.6115 0.830125 27.9792 1.28376 27.9895H6.54355C7.55111 27.9792 8.42502 27.3978 8.84748 26.553C9.02116 26.2072 9.11867 25.8166 9.11867 25.404V13.1312C9.11867 12.6775 8.75046 12.3093 8.29683 12.3093Z"
        fill="#5384EE"
      />
      <path
        d="M5.61146 23.6063V27.9895H3.96777V23.6063C3.96777 23.1526 4.33598 22.7844 4.78962 22.7844C5.24325 22.7844 5.61146 23.1526 5.61146 23.6063Z"
        fill="#EEBD53"
      />
    </g>
  </svg>
);

export default CommentLike;
