import classes from './Like.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Like from '@shared/svg/CommentLike';
import Layout from './ReactionLayout';
import type { ReactionProps } from './ReactionLayout';

interface LikeProps extends ReactionProps {
  hasHover?: boolean;
}

const LikeReaction: React.FC<LikeProps> = ({
  className,
  hasHover = true,
  ...rest
}) => {
  return (
    <Layout
      className={cnj(
        classes.likeContainer,
        hasHover && classes.hoverClass,
        className
      )}
      {...rest}
    >
      <Like />
    </Layout>
  );
};

export default LikeReaction;
