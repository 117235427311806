import { getType } from '@shared/hooks/useExtractInternalLinks';

// const urlRegex =
//   /\b(?:(https?:\/\/)|(www\.))[A-Za-z0-9-]+\.[A-Za-z]{2,}(?:\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]*)?\b/g;

const urlRegex = /(?:https?:\/\/|www\.)[A-Za-z0-9-]+\.[A-Za-z]{2,}\/?[-A-Za-z0-9+&@#/%?=~_|!:,.;]*/g;

export function getUrls(text: string): string[] {
  try {
    const usernames = text.match(/data-username="([^"]+)"/g);
    const matches = text
      .match(urlRegex)
      ?.filter(
        (matched) => !usernames?.some((username) => username.includes(matched))
      );

    if (!matches?.length) return [];
    return matches?.map((url: string) => {
      const prefix = 'https://';
      let link = url;
      if (link.substr(0, prefix.length) !== prefix) {
        link = prefix + link;
      }
      return link;
    });
  } catch (error) {
    return [];
  }
}

export function getExternalUrls(text: string): string[] {
  return getUrls(text)?.filter((item) => !getType(item));
}

export default {};
