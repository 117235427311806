import classes from './PostThumbnailMedia.video.module.scss';

import React from 'react';
import EasyCropper from '@lobox/uikit/EasyCropper';
import Flex from '@lobox/uikit/Flex';
import cnj from '@lobox/uikit/utils/cnj';

interface FeedImageProps {
  video: any;
  setVideoRef: (ref: React.MutableRefObject<HTMLVideoElement>) => null;
  size: number;
}

const onCropChangePlaceholder = () => null;

const PostThumbnailMediaVideo: React.FC<FeedImageProps> = ({
  setVideoRef,
  video,
  size,
}) => {
  return (
    <Flex
      className={classes.postThumbnailMediaVideoWrapper}
      style={{
        backgroundImage: video.secondaryUrl
          ? `url(${video.secondaryUrl})`
          : undefined,
      }}
    >
      <EasyCropper
        mediaProps={{
          loading: 'lazy',
        }}
        video={video.url}
        showGrid={false}
        zoomWithScroll={false}
        zoom={1.8}
        crop={{ x: 0, y: 0 }}
        cropSize={{
          width: size,
          height: size,
        }}
        onCropChange={onCropChangePlaceholder}
        classes={{
          cropAreaClassName: cnj(classes.videoCropArea, classes.grabCursor),
          mediaClassName: classes.videoMedia,
        }}
        setVideoRef={setVideoRef}
      />
    </Flex>
  );
};

export default PostThumbnailMediaVideo;
