const postTypes = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  CHECK_IN: 'CHECK_IN',
  HIGHLIGHT: 'HIGHLIGHT',
  GALLERY: 'GALLERY',
  OGLink: 'OGLink',
} as const;

export default postTypes;
