import { useMemo, useRef } from 'react';
import sum from 'lodash/sum';
import {
  APP_ENTITIES,
  PostReactions,
  useGlobalDispatch,
  useUrlQuery,
} from '@lobox/utils';
import { getExternalUrls } from '@shared/utils/getUrlsFromText';
import { searchFilterQueryParams } from '@shared/constants/search';
import postTypes from '@shared/constants/postTypes';
import useGetAppObject from '../../../../hooks/useGetAppObject';
import type { VariantType } from './feedElement.provider';
import { useFeedState, useFeedDispatch } from './feedElement.provider';

import Like from '../../../molecules/Reactions/Like';
import Boost from '../../../molecules/Reactions/Boost';
import Celebrate from '../../../molecules/Reactions/Celebrate';
import NotRelevant from '../../../molecules/Reactions/NotRelevant';
import DisLike from '../../../molecules/Reactions/DisLike';

import type { IFeedElement } from '@lobox/utils';

const PostAcceptableReactions = [
  PostReactions.like,
  PostReactions.celebrate,
  PostReactions.boost,
  PostReactions.comment,
];

type ReactionsKeys = keyof typeof PostReactions;
type ReactionType = (typeof PostReactions)[ReactionsKeys];
interface IReaction {
  id: string;
  type: ReactionType;
  ownerId: string;
}

const useFeedElement = (): {
  feedElement: IFeedElement;
  post: any;
  isBusinessPost: boolean;
  isUserPost: boolean;
  isSharedBusinessPost: boolean;
  isSharedUserPost: boolean;
  isMyPost: boolean;
  getPostOwnerInfo: (props: { userKey: string; pageKey: string }) => any;
  getSharedPostOwnerInfo: (props: { userKey: string; pageKey: string }) => any;
  hidePost: () => void;
  unFollowPostOwner: (sender: 'owner' | 'myFollowing') => void;
  undoUnfollow: () => void;
  undoHide: () => void;
  undoBlock: () => void;
  isTempHidden: boolean;
  unFollowed: boolean;
  ownerName: string;
  isBlocked: boolean;
  blockUser: () => void;
  isOpenComments: boolean;
  toggleComments: (state?: boolean) => void;
  variant?: VariantType;
  isReportModalOpen: boolean;
  toggleReportModal: (item?: any) => void;
  setCommentCounter: (number: number) => void;
  reactionsCount: number;
  reactions: any[];
  highlightData: any;
  hasBodyDescription: boolean;
  isSharedPost: boolean;
  isHighlight: boolean;
  isSharedHighlight: boolean;
  isSharedPage: boolean;
  isSharedGroup: boolean;
  isSimple: boolean;
  medias: any[] | undefined;
  location: any | undefined;
  sharedOfLocation: any | undefined;
  myReaction: IReaction;
  REACTIONS: typeof PostReactions;
  setReaction: (key: string) => void;
  isDeletedOriginalPost: boolean;
  follow: boolean;
  myFollowingReactionOnPost: any;
  isMyFollowingsReactionOnPost: boolean;
  postWithoutAnyReactions: boolean;
  layoutRef: any;
  isPage: boolean;
  followPostOwner: () => void;
  undoFollowPostOwner: () => void;
  followPageAttachmentOwner: () => void;
  isOpenReactionsInBottomSheet: boolean;
  selectedReactionInBottomSheet: any;
  toggleReactionsInBottomSheet: (data: any) => void;
  // for example in feed page i can directly say that i am following post owner
  isMyFollowingPostOwnerFromOtherPlaces: boolean;
  addReactionLocally: (reaction: ReactionType, response: any) => void;
  removeReactionLocally: (response: any) => void;
  increaseCommentCounter: () => void;
  decreaseCommentCounter: () => void;
  linksListInBody: string[];
  visibleNetworkInfoOnHeader: boolean;
  feedMediaType: keyof typeof postTypes;
  videos: Array<any>;
  images: Array<any>;
} => {
  const layoutRef = useRef(null);
  const query = useUrlQuery();
  const replacePostLocally = useFeedState('onReplacePost');

  const { isBusinessApp, authUser, businessPage } = useGetAppObject();

  const dispatch = useFeedDispatch();
  const appDispatch = useGlobalDispatch();

  const feedElement = useFeedState('feedElement');
  const {
    post = {},
    activities: postActivities = [],
    isMyFollowingPostOwnerFromOtherPlaces,
  } = feedElement || {};

  const visibleNetworkInfoOnHeader = useFeedState('visibleNetworkInfoOnHeader');
  const isTempHidden = useFeedState('isTempHidden');
  const unFollowed = useFeedState('unFollowed');
  const isBlocked = useFeedState('isBlocked');
  const isOpenComments = useFeedState('isOpenComments');
  const variant = useFeedState('variant');
  const isReportModalOpen = useFeedState('isReportModalOpen');
  const isOpenReactionsInBottomSheet = useFeedState(
    'isOpenReactionsInBottomSheet'
  );
  const selectedReactionInBottomSheet = useFeedState(
    'selectedReactionInBottomSheet'
  );

  const _activities = postActivities.sort(
    (a: any, b: any) => parseInt(b?.id, 10) - parseInt(a?.id, 10)
  );

  const __activities = _activities;

  // remove comment activities
  const activities = _activities.filter((x: any) => !x.commentId);

  const myReaction = useMemo(() => {
    const values = Object.values(PostReactions).filter((x) => x !== 'COMMENT');
    const act = activities.find(
      (x: any) =>
        (isBusinessApp
          ? x.ownerId === businessPage?.id
          : x.ownerId === authUser?.id) && values.includes(x.type)
    );
    return act;
  }, [activities]);

  const {
    ownerUserType,
    ownerId,
    ownerProfileInfo,
    ownerPageInfo,
    boostActionCounter,
    celebrateActionCounter,
    dislikeActionCounter,
    likeActionCounter,
    notRelevantActionCounter,
    medias: mediaArray,
    sharedOf,
    location: postLocation,
    highlight: highlightFormData,
    follow,
  } = post;
  const isUserPost = ownerUserType === APP_ENTITIES.person;
  const isBusinessPost = ownerUserType === APP_ENTITIES.page;

  const isSharedUserPost = sharedOf?.ownerUserType === APP_ENTITIES.person;
  const isSharedBusinessPost = sharedOf?.ownerUserType === APP_ENTITIES.page;

  if (post?.ownerProfileInfo) {
    post.ownerProfileInfo.fullName = `${post.ownerProfileInfo?.name} ${post.ownerProfileInfo?.surname}`;
  }
  if (post?.sharedOf?.ownerProfileInfo) {
    post.sharedOf.ownerProfileInfo.fullName = `${post.sharedOf.ownerProfileInfo?.name} ${post.sharedOf.ownerProfileInfo?.surname}`;
  }

  const makeObjectInfo =
    () =>
    ({ userKey, pageKey }: { userKey: string; pageKey: string }) =>
      isUserPost ? ownerProfileInfo?.[userKey] : ownerPageInfo?.[pageKey];

  const getPostOwnerInfo = makeObjectInfo();

  const makeSharedObjectInfo =
    () =>
    ({ userKey, pageKey }: { userKey: string; pageKey: string }) =>
      isSharedUserPost
        ? sharedOf?.ownerProfileInfo?.[userKey]
        : sharedOf?.ownerPageInfo?.[pageKey];

  const getSharedPostOwnerInfo = makeSharedObjectInfo();

  const isMyPost = isBusinessApp
    ? ownerId === businessPage?.id
    : ownerId === authUser?.id;

  const hidePost = () => {
    dispatch({
      type: 'HIDE_POST',
    });

    // if (layoutRef && layoutRef.current) {
    //   //@ts-ignore
    //   layoutRef.current.style.display = 'none';
    // }
  };

  const undoHide = () => {
    dispatch({
      type: 'UNDO_HIDE',
    });
  };

  const undoBlock = () => {
    dispatch({
      type: 'UNDO_BLOCK',
    });
  };

  const unFollowPostOwner = (sender: 'owner' | 'myFollowing') => {
    dispatch({
      type: 'UN_FOLLOW_POST_OWNER',
      payload: sender,
    });
  };

  const undoUnfollow = () => {
    dispatch({
      type: 'UNDO_UN_FOLLOW',
    });
  };

  const blockUser = () => {
    dispatch({
      type: 'BLOCK_USER',
    });
  };

  const toggleComments = (state?: boolean) => {
    if (variant !== 'light-box')
      dispatch({
        type: 'TOGGLE_COMMENTS',
        payload: state,
      });
  };

  const toggleReportModal = (item: any) => {
    appDispatch({
      type: 'TOGGLE_REPORT_MODAL',
      payload: {
        isOpen: true,
        data: item,
      },
    });
  };

  const toggleReactionsInBottomSheet = (data?: any) => {
    dispatch({
      type: 'TOGGLE_REACTIONS_IN_BOTTOM_SHEET',
      payload: data,
    });
  };

  const ownerName = getPostOwnerInfo({
    userKey: 'fullName',
    pageKey: 'title',
  });

  const setCommentCounter = (number: number) => {
    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        commentCounter: number,
      },
    };
    replacePostLocally(newFeedElement);
  };

  const increaseCommentCounter = () => {
    const commentCounter = (Number(post.commentCounter) || 0) + 1;

    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        commentCounter,
      },
    };

    replacePostLocally(newFeedElement);
  };

  const decreaseCommentCounter = () => {
    const commentCounter = (Number(post.commentCounter) || 0) - 1;

    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        commentCounter,
      },
    };

    replacePostLocally(newFeedElement);
  };

  const addReactionLocally = (reaction: ReactionType, response: any) => {
    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        ...(response || {}),
      },
    };
    if (myReaction) {
      const ___activities = feedElement.activities.map((x: IReaction) => {
        if (x?.id === myReaction?.id) {
          return { ...myReaction, type: reaction };
        }
        return x;
      });
      newFeedElement.activities = ___activities;
    } else {
      newFeedElement.activities.push({
        id: Math.random(),
        type: reaction,
        ownerId: isBusinessApp ? businessPage?.id : authUser?.id,
      });
    }
    replacePostLocally(newFeedElement);
  };

  const removeReactionLocally = (response: any) => {
    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        ...(response || {}),
      },
    };
    const __activities = feedElement.activities.filter(
      (x: IReaction) => x?.id !== myReaction?.id
    );
    newFeedElement.activities = __activities;
    replacePostLocally(newFeedElement);
  };

  const followPostOwner = () => {
    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        follow: true,
        localFollow: true,
      },
    };
    replacePostLocally(newFeedElement);
  };

  const undoFollowPostOwner = () => {
    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        follow: false,
        localFollow: false,
      },
    };
    replacePostLocally?.(newFeedElement);
  };

  const followPageAttachmentOwner = () => {
    const newFeedElement = {
      ...feedElement,
      post: {
        ...feedElement.post,
        sharedPage: {
          ...feedElement.post.sharedPage,
          follow: true,
        },
      },
    };
    replacePostLocally(newFeedElement);
  };

  const reactions = [
    {
      key: 'like',
      value: likeActionCounter || 0,
      Render: Like,
    },
    {
      key: 'boost',
      value: boostActionCounter || 0,
      Render: Boost,
    },
    {
      key: 'celebrate',
      value: celebrateActionCounter || 0,
      Render: Celebrate,
    },
    {
      key: 'disLike',
      value: dislikeActionCounter || 0,
      Render: DisLike,
    },
    {
      key: 'notRelevant',
      value: notRelevantActionCounter || 0,
      Render: NotRelevant,
    },
  ].sort((a, b) => (b.value || 0) - (a.value || 0));

  const reactionsCount = sum([
    parseInt(reactions[0].value, 10),
    parseInt(reactions[1].value, 10),
    parseInt(reactions[2].value, 10),
    parseInt(reactions[3].value, 10),
    parseInt(reactions[4].value, 10),
  ]);

  const isSharedPost = !!sharedOf;

  const isHighlight = !!highlightFormData;
  const isSharedHighlight = Boolean(sharedOf?.highlight);
  const highlightData = highlightFormData || sharedOf?.highlight || undefined;
  const isSharedPage = !!sharedOf?.page;
  const isSharedGroup = !!sharedOf?.group;
  const isSimple = !isHighlight && !isSharedPost;

  const medias = mediaArray?.length ? mediaArray : undefined;

  const location = postLocation?.title
    ? postLocation
    : sharedOf?.location?.title
      ? sharedOf?.location
      : undefined;

  const sharedOfLocation = sharedOf?.location?.title
    ? sharedOf?.location
    : undefined;

  const hasBodyDescription = !!post.body;

  const setReaction = () => {
    // changeReaction(key);
  };

  const isDeletedOriginalPost = post?.sharedOf?.deleted;

  const myFollowingReactionOnPost = __activities.find((x: any) => {
    if (
      (isBusinessApp
        ? x.ownerId === businessPage?.id
        : x.ownerId === authUser?.id) ||
      !PostAcceptableReactions.includes(x.type)
    ) {
      return false;
    }
    return x;
  });

  const isMyFollowingsReactionOnPost = myFollowingReactionOnPost
    ? PostAcceptableReactions.includes(myFollowingReactionOnPost.type)
    : false;

  const postWithoutAnyReactions =
    reactionsCount <= 0 && parseInt(post?.commentCounter || 0) <= 0
      ? true
      : false;
  const isPage = post?.sharedPage;

  const linksListInBody = useMemo(
    () =>
      getExternalUrls(
        post.body || post.sharedOf?.body || post.highlight?.description
      ),
    [post.body, post.sharedOf?.body, post.highlight?.description]
  );

  const videos = useMemo(
    () => medias?.filter((item) => item.type === 'VIDEO'),
    [medias]
  );
  const images = useMemo(
    () => medias?.filter((item) => item.type === 'IMAGE'),
    [medias]
  );

  const feedMediaType = useMemo(() => {
    const postType = query.get(
      searchFilterQueryParams.postType
    ) as keyof typeof postTypes;

    if (medias?.length > 1) return postTypes.GALLERY;

    const types = [
      isHighlight && postTypes.HIGHLIGHT,
      images?.length && postTypes.PHOTO,
      videos?.length && postTypes.VIDEO,
      location && postTypes.CHECK_IN,
      linksListInBody?.length && postTypes.OGLink,
      postTypes.TEXT,
    ].filter(Boolean);
    if (postType && types.includes(postType)) return postType;
    return types?.[0] || postTypes.TEXT;
  }, [isHighlight, location, medias]);

  return {
    // @ts-ignore
    visibleNetworkInfoOnHeader,
    linksListInBody,
    removeReactionLocally,
    addReactionLocally,
    isMyFollowingPostOwnerFromOtherPlaces,
    toggleReactionsInBottomSheet,
    isOpenReactionsInBottomSheet,
    selectedReactionInBottomSheet,
    followPageAttachmentOwner,
    followPostOwner,
    feedElement,
    isPage,
    undoBlock,
    layoutRef,
    postWithoutAnyReactions,
    myFollowingReactionOnPost,
    isMyFollowingsReactionOnPost,
    increaseCommentCounter,
    decreaseCommentCounter,
    follow,
    REACTIONS: PostReactions,
    myReaction,
    setReaction,
    getSharedPostOwnerInfo,
    isSharedBusinessPost,
    isSharedUserPost,
    hasBodyDescription,
    isHighlight,
    isSharedHighlight,
    isSharedPage,
    isSharedGroup,
    isSimple,
    isDeletedOriginalPost,
    medias,
    location,
    undoFollowPostOwner,
    sharedOfLocation,
    highlightData,
    isSharedPost,
    reactions,
    reactionsCount,
    setCommentCounter,
    isReportModalOpen,
    toggleReportModal,
    variant,
    isOpenComments,
    toggleComments,
    blockUser,
    isBlocked,
    ownerName,
    unFollowPostOwner,
    undoUnfollow,
    unFollowed,
    undoHide,
    isTempHidden,
    hidePost,
    post,
    isBusinessPost,
    isUserPost,
    isMyPost,
    getPostOwnerInfo,
    feedMediaType,
    videos,
    images,
  };
};

export default useFeedElement;
