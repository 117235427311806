import * as React from 'react';

const CommentBoost: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M12.1179 7.25628L11.4473 6.5857C9.65565 4.79403 6.7507 4.79403 4.95903 6.5857L1.6844 9.86033C1.51952 10.0252 1.61489 10.3075 1.84595 10.3387L7.15074 11.0539L12.1179 7.25628Z"
        fill="#EEBD53"
      />
      <path
        d="M20.744 15.8826L21.4145 16.5531C23.2062 18.3448 23.2062 21.2498 21.4145 23.0414L18.1399 26.3161C17.975 26.481 17.6927 26.3856 17.6615 26.1545L16.9463 20.8497L20.744 15.8826Z"
        fill="#BE9742"
      />
      <path
        d="M25.0395 2.96106L17.6912 11.7036L11.2125 16.788L6.22656 11.8021C6.89703 9.2744 8.35905 7.28176 10.227 5.71102C13.2637 3.15629 17.3721 1.71779 20.8845 0.911865L25.0395 2.96106Z"
        fill="#5384EE"
      />
      <path
        d="M16.1979 21.7739L11.2119 16.7879L25.0389 2.96094L27.0881 7.11593C26.2822 10.6283 24.8436 14.7367 22.2889 17.7735C20.7182 19.6414 18.7255 21.1034 16.1979 21.7739Z"
        fill="#4269BE"
      />
      <path
        d="M19.4609 8.5394L17.6909 11.7036L14.5267 13.4736C13.1643 12.1112 13.1643 9.90183 14.5267 8.5394C15.8891 7.17697 18.0985 7.17697 19.4609 8.5394Z"
        fill="#D5E1FA"
      />
      <path
        d="M19.4605 13.4735C18.0981 14.8359 15.8888 14.8359 14.5264 13.4735L19.4605 8.53931C20.823 9.90174 20.823 12.1111 19.4605 13.4735Z"
        fill="#B5CAF7"
      />
      <path
        d="M11.2125 16.7879L10.6916 18.8758L8.41837 19.5821L5.14062 16.3043C4.97967 16.1434 4.92154 15.906 4.99045 15.6889L6.2266 11.802L11.2125 16.7879Z"
        fill="#4269BE"
      />
      <path
        d="M16.198 21.774L12.3112 23.0102C12.0941 23.0791 11.8566 23.0209 11.6957 22.86L8.41797 19.5822L11.2121 16.7881L16.198 21.774Z"
        fill="#314F8E"
      />
      <path
        d="M2.79658 18.1882L0.817437 18.253C0.351007 18.2683 -0.0147973 18.6588 0.00046047 19.1253C0.00778859 19.3485 0.101031 19.5487 0.247429 19.6951C0.406898 19.8545 0.629531 19.9502 0.872781 19.9422L2.85192 19.8774C3.31835 19.8621 3.68416 19.4716 3.6689 19.0052C3.65353 18.5387 3.26312 18.173 2.79658 18.1882Z"
        fill="#FFB760"
      />
      <path
        d="M9.74732 27.1827L9.81212 25.2035C9.82738 24.7371 9.46163 24.3465 8.99515 24.3313C8.52866 24.316 8.13814 24.6818 8.12288 25.1482L8.05807 27.1274C8.05015 27.3706 8.14574 27.5932 8.30526 27.7527C8.45166 27.8991 8.65182 27.9924 8.87505 27.9997C9.34148 28.0149 9.732 27.6491 9.74732 27.1827Z"
        fill="#FF8C00"
      />
      <path
        d="M6.46141 21.5389L5.2205 23.2047L3.38803 24.6123C3.05816 24.2824 3.05816 23.747 3.38803 23.4172L5.26633 21.5389C5.59615 21.2091 6.13154 21.209 6.46141 21.5389Z"
        fill="#FFB760"
      />
      <path
        d="M6.46108 22.734L4.58278 24.6123C4.25291 24.9421 3.71752 24.9421 3.3877 24.6123L6.46113 21.5388C6.79095 21.8687 6.7909 22.4041 6.46108 22.734Z"
        fill="#FF8C00"
      />
      <path
        d="M27.6002 0.399498L25.7359 3.65816L22.7987 5.20106C21.6881 4.09041 20.9734 2.58459 20.8838 0.91181C23.1593 0.388342 25.185 0.13142 26.5075 0.00629469C26.9338 -0.0343381 27.3254 0.124638 27.6002 0.399498Z"
        fill="#EEBD53"
      />
      <path
        d="M27.0881 7.1161C25.4154 7.02647 23.9095 6.31181 22.7988 5.20117L27.6003 0.399658C27.8752 0.674518 28.0342 1.06613 27.9935 1.49237C27.8685 2.81493 27.6115 4.84061 27.0881 7.1161Z"
        fill="#BE9742"
      />
    </g>
  </svg>
);

export default CommentBoost;
