import * as React from 'react';

const CommentDislike: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3371_5336)">
      <path
        d="M9.17322 14.047H16.2816C16.6521 14.047 16.9767 14.2948 17.0744 14.6522C17.172 15.0095 17.0186 15.388 16.6997 15.5765C13.6026 17.4066 11.9938 19.5413 11.7817 22.1028C11.7077 22.9917 10.9159 23.7018 10.0171 23.69C9.09999 23.6754 8.2408 23.3078 7.59729 22.6548C6.95329 22.0012 6.59837 21.1358 6.59804 20.2179C6.59804 20.2178 6.59804 20.2177 6.59804 20.2175V19.9414C6.59804 17.6992 7.26217 16.2696 8.48755 14.4158C8.63985 14.1855 8.89727 14.047 9.17322 14.047Z"
        fill="#FFC380"
      />
      <path
        d="M0.461727 13.1155C0.461727 14.5355 1.61689 15.6907 3.03685 15.6907H16.2817C16.4288 15.6907 16.5733 15.6512 16.6999 15.5763C17.6297 15.0265 18.5684 14.574 19.3227 14.2103C19.5858 14.0835 19.8343 13.9636 20.0604 13.8507C20.3388 13.7114 20.5146 13.4269 20.5146 13.1155V2.59592C20.5146 2.28458 20.3388 2.00004 20.0604 1.86081C16.7396 0.200659 13.9792 -9.91821e-05 10.7602 -9.91821e-05C10.2617 -9.91821e-05 9.75193 0.0047121 9.2277 0.00963402C8.6479 0.0151024 8.04847 0.0207891 7.42 0.0207891H4.82185C3.40195 0.0207891 2.24673 1.17595 2.24673 2.59592C2.24673 3.07334 2.37727 3.52079 2.60466 3.90453C1.86479 4.35745 1.37009 5.17323 1.37009 6.10248C1.37009 6.5799 1.50063 7.02735 1.72802 7.4111C0.98815 7.86402 0.493446 8.67979 0.493446 9.60904C0.493446 10.2769 0.749002 10.8862 1.16742 11.3442C0.71176 11.8244 0.461727 12.4507 0.461727 13.1155Z"
        fill="#FFCF99"
      />
      <path
        d="M2.17097 20.2826H3.92425C4.3781 20.2826 4.74609 20.6506 4.74609 21.1044C4.74609 21.5583 4.3781 21.9263 3.92425 21.9263H2.17097C1.71712 21.9263 1.34913 21.5583 1.34913 21.1044C1.34913 20.6506 1.71706 20.2826 2.17097 20.2826Z"
        fill="#29CCB1"
      />
      <path
        d="M14.4434 20.2826H16.1967C16.6506 20.2826 17.0186 20.6506 17.0186 21.1044C17.0186 21.5583 16.6506 21.9263 16.1967 21.9263H14.4434C13.9896 21.9263 13.6216 21.5583 13.6216 21.1044C13.6216 20.6506 13.9895 20.2826 14.4434 20.2826Z"
        fill="#29CCB1"
      />
      <path
        d="M11.8124 24.8377C12.0965 24.8377 12.3726 24.9851 12.5248 25.2487L13.4015 26.7671C13.6284 27.1602 13.4937 27.6629 13.1007 27.8898C12.7077 28.1167 12.205 27.982 11.9781 27.589L11.1014 26.0706C10.8745 25.6775 11.0092 25.1748 11.4022 24.9479C11.5316 24.8732 11.673 24.8377 11.8124 24.8377Z"
        fill="#29CCB1"
      />
      <path
        d="M6.55479 24.8377C6.69425 24.8377 6.83556 24.8731 6.96495 24.9479C7.35799 25.1749 7.49268 25.6775 7.26573 26.0705L6.38909 27.5889C6.16219 27.982 5.6595 28.1168 5.26647 27.8897C4.87343 27.6628 4.73873 27.1601 4.96568 26.7671L5.84233 25.2487C5.99441 24.985 6.27075 24.8377 6.55479 24.8377Z"
        fill="#29CCB1"
      />
      <path
        d="M22.3885 4.3388V0.0103931H24.0322V4.3388C24.0322 4.79243 23.664 5.16064 23.2104 5.16064C22.7567 5.16064 22.3885 4.79243 22.3885 4.3388Z"
        fill="#73C3FF"
      />
      <path
        d="M2.17097 20.2826H3.92425C4.3781 20.2826 4.74609 20.6506 4.74609 21.1044C4.74609 21.5583 4.3781 21.9263 3.92425 21.9263H2.17097C1.71712 21.9263 1.34913 21.5583 1.34913 21.1044C1.34913 20.6506 1.71706 20.2826 2.17097 20.2826Z"
        fill="#8493A8"
      />
      <path
        d="M14.4434 20.2826H16.1967C16.6506 20.2826 17.0186 20.6506 17.0186 21.1044C17.0186 21.5583 16.6506 21.9263 16.1967 21.9263H14.4434C13.9896 21.9263 13.6216 21.5583 13.6216 21.1044C13.6216 20.6506 13.9895 20.2826 14.4434 20.2826Z"
        fill="#8493A8"
      />
      <path
        d="M11.8124 24.8377C12.0965 24.8377 12.3726 24.9851 12.5248 25.2487L13.4015 26.7671C13.6284 27.1602 13.4937 27.6629 13.1007 27.8898C12.7077 28.1167 12.205 27.982 11.9781 27.589L11.1014 26.0706C10.8745 25.6775 11.0092 25.1748 11.4022 24.9479C11.5316 24.8732 11.673 24.8377 11.8124 24.8377Z"
        fill="#8493A8"
      />
      <path
        d="M6.55479 24.8377C6.69425 24.8377 6.83556 24.8731 6.96495 24.9479C7.35799 25.1749 7.49268 25.6775 7.26573 26.0705L6.38909 27.5889C6.16219 27.982 5.6595 28.1168 5.26647 27.8897C4.87343 27.6628 4.73873 27.1601 4.96568 26.7671L5.84233 25.2487C5.99441 24.985 6.27075 24.8377 6.55479 24.8377Z"
        fill="#8493A8"
      />
      <path
        d="M22.3885 4.3388V0.0103931H24.0322V4.3388C24.0322 4.79243 23.664 5.16064 23.2104 5.16064C22.7567 5.16064 22.3885 4.79243 22.3885 4.3388Z"
        fill="#73C3FF"
      />
      <path
        d="M19.7032 15.6907H26.7162C27.1699 15.6907 27.5381 15.3225 27.5381 14.8688V0.84269C27.5381 0.388511 27.1699 0.0208454 26.7162 0.0104551H21.4565C20.4489 0.0208454 19.575 0.602173 19.1525 1.44704C18.9788 1.79277 18.8813 2.18341 18.8813 2.59597V14.8688C18.8813 15.3225 19.2495 15.6907 19.7032 15.6907Z"
        fill="#5384EE"
      />
      <path
        d="M22.3885 4.39373V0.0105286H24.0322V4.39373C24.0322 4.84737 23.664 5.21558 23.2104 5.21558C22.7567 5.21558 22.3885 4.84737 22.3885 4.39373Z"
        fill="#EEBD53"
      />
    </g>
    <defs>
      <clipPath id="clip0_3371_5336">
        <rect
          width="28"
          height="28"
          fill="transparent"
          transform="matrix(-1 0 0 -1 28 28)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CommentDislike;
