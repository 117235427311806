// @ts-nocheck
import { useCreateContext } from '@lobox/utils';
import { reducer, initialState } from './feedElement.reducer';

export type VariantType =
  | 'search-view'
  | 'simple'
  | 'light-box'
  | 'attachment-view'
  | 'message';

const [state, dispatch, provider] = useCreateContext(reducer, initialState);
export const useFeedState = state;
export const useFeedDispatch = dispatch;
export const FeedElementProvider = provider;
